<script>
//import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
import PageHeader from "@/components/page-header";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";

import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapState } from "vuex";
import axios from 'axios';
import Paginate from 'vuejs-paginate-next';

import {
    authMethods,
    authFackMethods,
    notificationMethods,
} from "@/state/helpers";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    page: {
        title: "Users",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    data() {
        return {
            title: "Users",
            items: [{
                text: "Users",
                href: "/users",
            },
            {
                text: "Users",
                active: true,
            },
            ],
            value: ['javascript'],
            file: "",
            imgsrc: "",
            date: null,
            session_usertype: localStorage.getItem('usertype'),
            submitted: false,
            regError: null,
            regSuccess: null,
            tryingToRegister: false,
            isRegisterError: false,
            registerSuccess: false,
            userserror: false,
            userserrormsg: '',
            usersok: false,
            is_admin: false,
            usersokmsg: '',
            userSearchTerm: '',
            currentpage: 0,
            totalpage: 0,
            totalrows: 0,

            updateuser_id: 0,
            usertype: localStorage.getItem('usertype'),
            session_user_id: localStorage.getItem('userid'),
            login_user_work_type: localStorage.getItem('work_type_string'),
            userstatus: '',
            userslist: [],
            passerror: false,
            passok: false,
            passerrormsg: '',
            passokmsg: '',
            username: '',
            email: '',
            type: 'Writer',
            status: 'Active',
            isadd: true,
            modaltitle: '',
            buttonname: '',
            password: '',
            cpassword: '',
            worktypes: [],
            names: [],
            worktypescheckbox: [],
            work_types: [],

        };
    },
    validations: {
        user: {
            username: {
                required: helpers.withMessage("User name is required", required),
            },

            email: {
                required: helpers.withMessage("Email is required", required),
                email: helpers.withMessage("Please enter valid email", email),
            }
        },
    },
    computed: {
        ...mapState("authfack", ["status"]),
        notification() {
            return this.$store ? this.$store.state.notification : null;
        },
        checkedNames() {
            return this.names.filter(item => item.checked).map(name => name.name)
        }
    },
    components: {
        Layout,
        paginate: Paginate,
        PageHeader,

        //Multiselect,
        //flatPickr
    }, mounted: function () {
        this.getUserData();
        if (this.usertype !== 'Task Manager') {
            this.getALLWorkTypes();
        }

    },
    methods: {

        clickCallback: function (pageNum) {
            // alert("pageNum==" + pageNum);
            this.currentpage = parseInt(pageNum) - 1;
            this.getUserData();

        },
        get_work_type_names(work_type_ids) {

            //console.log("work types===" + work_type_ids)
            var final_names = '';
            var ids = work_type_ids.split(',');

            for (var i = 0; i < ids.length; i++) {
                var id = ids[i];

                for (var k = 0; k < this.work_types.length; k++) {
                    console.log(this.work_types[k].id + "------" + id)
                    if (this.work_types[k].id == id) {
                        final_names += this.work_types[k].name + ', ';
                    }
                }
            }
            return final_names.slice(0, -1);

        },
        async getUserData() {
            //var userid = localStorage.getItem('userid')
            // this.usertype = localStorage.getItem('usertype')
            // this.user.name = localStorage.getItem('username');
            // this.user.useremail = localStorage.getItem('useremail');
            //alert(this.usertype);


            //alert(parseInt(this.currentpage)+ "======" + this.userSearchTerm);
            var result = await axios.post(appConfig.api_url + 'users', {
                filter: '' + this.userSearchTerm,
                page: this.currentpage,
                usertype: this.usertype,
                created_by:this.session_user_id,
            })

            var users = result.data.data.user_res;
         //   alert(JSON.stringify(this.users));
            this.totalpage = result.data.totalpages;
            this.totalrows = result.data.totalrows;
            this.work_types = result.data.work_types;
           // alert(JSON.stringify(this.users.length));
            //  alert(this.work_types)
            //  alert(this.work_types.length)

            //  alert(JSON.stringify(result.data));
            //  console.log(JSON.stringify(this.work_types));
            this.userslist = users;


            // if (result.data.status == 'success') {

            // } else {
            // }




        },
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        onselect() {
            const file = this.$refs.file.files[0];
            this.file = file;
            this.imgsrc = URL.createObjectURL(file);
            //alert("on select ="+this.file);
        }, async updateuserprofile() {
            this.submitted = true;
            // stop here if form is invalid

            this.tryingToRegister = true;
            this.regError = null;

            var goto = 'updateuserprofile';
            if (this.isadd) {
                goto = 'register';

                if (this.password !== this.cpassword) {
                    // alert('Password mismatched');
                    this.regError = 'Password mismatched';
                    this.isRegisterError = true;
                    return;
                }

            }
            var a = '';
            var wrok_type_string = '';
            if (this.usertype !== 'Task Manager') {
                for (var i = 0; i < this.worktypescheckbox.length; i++) {
                    a += this.worktypescheckbox[i] + ",";
                }

                wrok_type_string = a.substring(0, a.length - 1);

            } else { // for task manager 
                wrok_type_string = this.login_user_work_type;
            }


            const result = await axios.post(appConfig.api_url + '' + goto, {
                Id: this.updateuser_id,
                Name: this.username,
                Email: this.email,
                type: this.type,
                Status: this.status,
                Password: this.password,
                work_type: wrok_type_string,
                created_by:this.session_user_id
            });

            //alert(JSON.stringify(result.data))

            if (result.data.status == 'errors') {
                this.tryingToRegister = false;
                this.regError = result.data.data.data;
                this.isRegisterError = true;
                // return this.authError = result.data.data.data;
            } else {
                this.tryingToRegister = false;
                this.isRegisterError = false;
                this.registerSuccess = true;
                this.regSuccess = result.data.data.data;
                this.getUserData();
                document.getElementById('closeupdatemodalbutton').click();
                // localStorage.setItem('username', this.user.name)
                // localStorage.setItem('useremail', this.user.useremail)
                // //   alert(result.data.data.data);
                //  console.log(result.data.data.data);
            }
            


        }, async filteruser() {
            this.userslist = [];
            this.getUserData();
        }, updateuser(id, name, email, type, status, work_type) {
            this.modaltitle = 'Update user';
            this.buttonname = 'Update';
            this.isadd = false;
            this.updateuser_id = id;
            this.username = name;
            this.email = email;
            this.type = type;
            this.status = status;
            this.worktypescheckbox=[];
            var ids = work_type.split(',');

            for (var i = 0; i < ids.length; i++) {
                this.worktypescheckbox.push(ids[i]);
            }
            // this.worktypescheckbox = [work_type];
            this.password = '';
            document.getElementById('openupdatemodalbutton').click();
            this.isRegisterError = false;
            this.registerSuccess = false;

        }, createuser() {
            this.isRegisterError = false;
            this.registerSuccess = false;
            this.modaltitle = 'Create new user';
            this.buttonname = 'Add';
            this.isadd = true;
            this.updateuser_id = -1;
            this.username = '';
            this.email = '';
            this.type = 'Writer';
            this.status = 'Active';
            this.password = '';
            this.worktypescheckbox=[];
            document.getElementById('openupdatemodalbutton').click();
        }, async UpdateStatus() {

            //alert(this.updateuser_id + "====" + this.userstatus)
            await axios.post(appConfig.api_url + 'updateUserStatus', {
                Id: this.updateuser_id,
                Status: this.userstatus
            })


            //alert(JSON.stringify(result.data));
            //console.log(JSON.stringify(result));
            // this.userslist = users;
            // document.getElementById('openupdatemodalbutton').click();
            this.usersok = true;
            this.usersokmsg = 'User status updated'
            this.getUserData();


        }, async deleteeuser(id) {

            let text = "Do you want to DELETE user?";
            if (confirm(text) == true) {
                await axios.post(appConfig.api_url + 'deleteUser/' + id)
                this.usersok = true;
                this.usersokmsg = 'User Deleted'
                this.getUserData();
            }
        }, async getALLWorkTypes() {

            //alert(parseInt(this.currentpage)+ "======" + this.userSearchTerm);
            var result = await axios.post(appConfig.api_url + 'categories/getall', {
                name: '',
                cat_name: 'Work Type'
            })
            this.worktypes = result.data.data.data;
            // alert(this.worktypes)
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-3" style="background-color: white">
            <!-- <h3 class="m-2">Users</h3> -->
            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <div class="form-icon m-2">
                    <input v-model="userSearchTerm" type="text" @keyup="filteruser"
                        placeholder="Search by name, email, type, status" class="form-control form-control-icon"
                        aria-label="Recipient's username" aria-describedby="button-addon2">
                    <i class=" ri-search-line"></i>
                </div>
                <div class="page-title-right m-2">
                    <button class="btn btn-success " style="float:right" @click="createuser">+ Create User</button>
                </div>
            </div>
        </div>

        <form class="needs-validation" @submit.prevent="updateuserprofile" enctype="multipart/form-data">
            <div class="row">
                <div class="card ">


                    <div class="card-body m-2">
                        <div class="col-12">
                            <b-alert v-model="registerSuccess" class="m-3" style="padding-bottom:10px" variant="success"
                                dismissible>
                                {{ regSuccess }}
                            </b-alert>
                        </div>

                        <div class="table-responsive table-card">
                            <table class="table align-middle table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col">Action</th>

                                        <th scope="col">ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">User Type</th>
                                        <th scope="col" style="width:20%">Work Types</th>
                                        <th scope="col">Created At</th>
                                        <!-- <th scope="col">Status</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user) of userslist" v-bind:key="user.Id"
                                        :style="user.Status == 'Active' ? 'background-color:#ddfcdd' : 'background-color:#ffe5e5'" >
                                        <td >
                                            <a style="padding-right:10px"
                                                @click="updateuser(user.Id, user.Name, user.Email, user.type, user.Status, user.work_type)"><i
                                                    class="ri-edit-2-fill"></i></a>
                                            <a @click="deleteeuser(user.Id)"><i class=" ri-close-circle-fill"></i></a>
                                        </td>
                                        <td >{{ user.Id }}</td>
                                        <td >{{ user.Name }}</td>
                                        <td >{{ user.Email }}</td>
                                        <td>{{ user.type }}</td>
                                        <td  style="width:20%">{{ get_work_type_names(user.work_type) }}</td>
                                        <td >{{ user.CreatedAt }}</td>
                                        <!-- <td>{{ user.Status }}</td> -->

                                    </tr>

                                </tbody>

                            </table>
                            <!-- end table -->
                        </div>

                        <div class="mt-4">
                            <div class="col-12">
                                <div class="page-title-box d-sm-flex align-items-center justify-content-between">

                                    <p style="float:left">Rows: {{ totalrows }}</p>

                                    <div class="page-title-right" style="float:right">
                                        <paginate v-model="page" :page-count="totalpage" :margin-pages="2"
                                            :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'"
                                            :container-class="'pagination'" :page-class="'page-item'">
                                        </paginate>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!--end tab-pane-->


                    </div>
                </div>
                <!--end col-->
            </div>
        </form>
        <div class="row">

        </div>

        <!-- start of create document edit modal -->
        <a data-bs-toggle="modal" id="openupdatemodalbutton" data-bs-target="#updatecreatedocumentsmodal"></a>
        <div class="modal fade" id="updatecreatedocumentsmodal" tabindex="-1" aria-labelledby="exampleModalgridLabel">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalheader">{{ modaltitle }}</h5>
                        <button type="button" id="closeupdatemodalbutton" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <b-alert v-model="isRegisterError" class="mt-3" variant="danger" dismissible>{{ regError }}
                        </b-alert>
                        <form @submit.prevent="updateuserprofile" id="updateDocumentform">
                            <div class="row g-3">
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="phonenumberInput" class="form-label">Name</label>
                                        <input required type="text" class="form-control" id="mobile" v-model="username"
                                            placeholder="Enter your Name" />
                                    </div>
                                </div>
                                <!--end col-->
                                <div class="col-lg-6">
                                    <div class="mb-3">
                                        <label for="emailInput" class="form-label">Email Address</label>
                                        <input type="email" class="form-control" v-model="email"
                                            placeholder="Enter your email" required />

                                    </div>
                                </div>
                                <div class="col-lg-6" v-if="isadd">
                                    <div class="mb-3">
                                        <label for="emailInput" class="form-label">Password</label>
                                        <input type="password" class="form-control" v-model="password"
                                            placeholder="Enter your password" required />
                                    </div>
                                </div>
                                <div class="col-lg-6" v-if="isadd">
                                    <div class="mb-3">
                                        <label for="emailInput" class="form-label">Confirm Password</label>
                                        <input type="password" class="form-control" v-model="cpassword"
                                            placeholder="Enter your password" required />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <label for="firstName" class="form-label">Select Type</label>
                                    <select required v-model="type" class="form-select mb-3"
                                        aria-label="Default select example">
                                        <option value="Writer" >Writer</option>
                                        <option value="Admin" v-if="usertype == 'Super Admin'">Admin</option>
                                        <option value="Bidder" v-if="usertype !== 'Task Manager'">Bidder</option>
                                        <option value="Task Manager" v-if="usertype !== 'Task Manager'">Task Manager
                                        </option>
                                        <option value="Super Admin" v-if="usertype == 'Super Admin'">Super Admin
                                        </option>
                                    </select>
                                </div>
                                <div class="col-lg-6">
                                    <div>
                                        <label for="firstName" class="form-label">Select Status</label>
                                        <select required v-model="status" class="form-select mb-3"
                                            aria-label="Default select example">
                                            <option value="Active">Active</option>
                                            <option value="Disabled">Disabled</option>
                                        </select>
                                        <input type="hidden" v-model="updateuser_id">

                                    </div>
                                </div>
                                <!-- Custom Checkboxes Color -->
                                <div class="col-lg-12" v-if="usertype !== 'Task Manager'">

                                    <div class="form-check form-check-success form-check-inline mb-3"
                                        v-for="(item, index) in worktypes" :key="index">
                                        <input class="form-check-input" type="checkbox" :value="item.id"
                                            v-model="worktypescheckbox">
                                        <label class="form-check-label" :for="item.name">{{ item.name }}</label>
                                    </div>
                                    <!-- <span><br>Checked names: {{ worktypescheckbox }}</span> -->


                                </div>
                                <!--end col-->

                                <!--end col-->
                                <div class="col-lg-12">
                                    <div class="hstack gap-2 justify-content-end">
                                        <button type="button" class="btn btn-light"
                                            data-bs-dismiss="modal">Close</button>
                                        <button type="submit" class="btn btn-primary">{{ buttonname }}</button>
                                    </div>
                                </div>
                                <!--end col-->
                            </div>
                            <!--end row-->
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!--end row-->
    </Layout>
</template>